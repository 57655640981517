 /* General Styles */
.report-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    text-align: center;
    margin-bottom: 40px;
    color: white;
  }
  
  .section-title {
    margin-top: 40px;
    margin-bottom: 20px;
    border-bottom: 2px solid #ccc;
    padding-bottom: 10px;
    color: white;
  }
  
  /* Quiz Styles */
  .quiz-section {
  
    padding: 20px;
    border-radius: 5px;
  }
  
  .quiz-question h3 {
    margin-bottom: 10px;
  }
  
  .quiz-question p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .quiz-option {
    display: block;
    width: 100%;
    text-align: left;
    background-color: rgb(1, 56, 90);
    border: 1px solid #ddd;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.2s;
    color: white;
  }
  
  .quiz-option:hover {
    background-color: rgb(3, 77, 123);
  }
  
  .quiz-result {
    text-align: center;
  }
  
  .quiz-result h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Flashcard Styles */
  .flashcard-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .flashcard {
    color: white;
    width: 100%;
    max-width: 500px;
    height: 200px;
    /* background-color: #fff; */
    border: 2px solid #b9b9b9;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    perspective: 1000px;
  }
  
  .flashcard-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 18px;
    backface-visibility: hidden;
    transition: transform 0.6s;
  }
  
  .flashcard:hover .flashcard-content {
    transform: rotateY(180deg);
  }
  
  /* Button Styles */
  .learnbtn{
   color: white;
    border: 0px;
     box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.192); 
    background-color: rgb(1, 56, 90);
    border-radius: 20px;
  }
  
  .btn:hover {
    /* background-color: #005f99; */
  }
  .report-container{
    color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .flashcard {
      height: 150px;
    }
  
    .quiz-option {
      font-size: 16px;
    }
  }
  